import { combineReducers } from 'redux';

// import Reducers

import permissionsReducer from './permissionsReducer';
import authReducer from './authReducer';
import customizationReducer from './customizationReducer';
import empReducer from './empReducer';

//-----------------------|| COMBINE REDUCER ||-----------------------//

const reducer = combineReducers({
    auth: authReducer,
    permissions: permissionsReducer,
    customization: customizationReducer,
    emp: empReducer,
});

export default reducer;
