import { Redirect, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

// routes
import MainRoutes from './main-routes';
import AuthenticationRoutes from './authentication-routes';

//-----------------------|| ROUTING RENDER ||-----------------------//

const Routes = () => {

    const isAuthenticated = useSelector((state) => state.auth.isLoggedIn);

    return (
        <Switch>
            {isAuthenticated == false ? <Redirect exact from="/" to="/login" /> : <Redirect exact from="/" to="/dashboard" />}
            {isAuthenticated ? <MainRoutes /> : <AuthenticationRoutes />}
        </Switch>
    );
};

export default Routes;
