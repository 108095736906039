import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function PermissionGateway({ children, moduleAlias, permissionAlias }) {

	const permissionsMatrix = useSelector((state) => state.permissions.permissionsMatrix)

    const [renderedElement, setRenderedElement] = useState('')

    useEffect(() => {
        if (permissionsMatrix && permissionsMatrix.length > 0) {
            const permission = permissionsMatrix.find(element => element.permission.module.alias == moduleAlias && element.permission.alias == permissionAlias)
    
            if (permission) {
                if (permission.flag == 1) {
                    setRenderedElement(children)
                }else{
                    setRenderedElement('')
                }
            }
        }
    }, [permissionsMatrix,children]);

    return (
        <>
            {
                renderedElement
            }
        </>
    );
}

export default PermissionGateway;