// API
import axios from 'axios'
import { LOGOUT } from '../redux/actions/authActions';
import { REMOVE_PERMISSIONS } from '../redux/actions/permissionsActions';
import { store } from '../redux/store'

const host = window.location.host;

let baseURL = ''
if (host === 'localhost:3000' || host === '127.0.0.1:3000') {
    baseURL = 'http://localhost:8000/'
} else if (host === 'uat-emanage.xscad2.com') {
    baseURL = 'https://uat-emanage-api.xscad2.com/'
} else if (host === 'emanage.xscad2.com') {
    baseURL = 'https://emanage-api.xscad2.com/'
}

const API = axios.create({
    baseURL: baseURL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
})

API.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        store.dispatch({ type: REMOVE_PERMISSIONS });
        store.dispatch({ type: LOGOUT });
        window.location.reload();
    }
    throw error
});

export default API