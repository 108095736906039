import React from 'react';
import {Link} from 'react-router-dom';

const Footer = ()=>{

    return (
        <footer className="footer">
            <div className="container-fluid clearfix">
              <h6>
              Note: The information and applications stored within the eManage Suite are private & confidential. They are strictly for the use of XS CAD Employees ONLY. Thank you for your continued support.
© 2023 | XS CAD
              </h6>
              {/* <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © bootstrapdash.com 2020</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"> Free <a href="https://www.bootstrapdash.com/bootstrap-admin-template/" target="_blank">Bootstrap admin templates </a> from Bootstrapdash.com</span> */}
            </div>
          </footer>
    );

} 

export default Footer;