import API from '../util/api';
import { store } from '../redux/store'
import axios from 'axios';

const token = store.getState().auth.authToken;

// login
export const login = async (formData) => {
    try {
        const sanctumResponse = await API.get('/sanctum/csrf-cookie')
        let headers = sanctumResponse.config.headers;
        const response = await API.post(`/api/login`, formData, { headers: headers });
        if (response.status == 200) {
            return response.data
        }
    } catch (errors) {
        throw (errors)
    }
}

// Logout
export const logoutSession = async (token1) => {
    try {
        const sanctumResponse = await API.get('/sanctum/csrf-cookie')
        let headers = sanctumResponse.config.headers;
        headers.Authorization = `Bearer ${token1}`

        const response = await API.post(`/api/logout`, {}, { headers: headers });
        if (response.status == 200) {
            return response.data
        }
    } catch (errors) {
        throw (errors)
    }
}

// Forgot Password
export const forgotPassword = async (formData) => {
    try {
        const sanctumResponse = await API.get('/sanctum/csrf-cookie')
        let headers = sanctumResponse.config.headers;
        const response = await API.post(`/api/forgotpassword`, formData, { headers: headers });
        if (response.status == 200) {
            return response.data
        }
    } catch (errors) {
        throw (errors)
    }
}

// Forgot Password
export const setPassword = async (formData) => {
    try {
        const sanctumResponse = await API.get('/sanctum/csrf-cookie')
        let headers = sanctumResponse.config.headers;
        const response = await API.post(`/api/setpassword`, formData, { headers: headers });
        if (response.status == 200) {
            return response.data
        }
    } catch (errors) {
        throw (errors)
    }
}


// Change Password (User)
export const changePassword = async (formData) => {
    try {
        const sanctumResponse = await API.get('/sanctum/csrf-cookie')
        let headers = sanctumResponse.config.headers;
        headers.Authorization = `Bearer ${token}`
        const response = await API.post(`api/changepassword`, formData, { headers: headers });
        if (response.status == 200) {
            return response.data
        }
    } catch (errors) {
        throw (errors)
    }
}


// Store Device Token
export const storeDeviceToken = async (formData, token1) => {
    try {
        const sanctumResponse = await API.get('/sanctum/csrf-cookie')
        let headers = sanctumResponse.config.headers;
        headers.Authorization = `Bearer ${token1}`
        const response = await API.post(`api/store_device_token`, formData, { headers: headers });
        if (response.status == 200) {
            return response.data
        }
    } catch (errors) {
        throw (errors)
    }
}


export const verifyCaptchaToken = async (formData) => {
    try {
        const sanctumResponse = await API.get('/sanctum/csrf-cookie')
        let headers = sanctumResponse.config.headers;
        const response = await API.post(`/api/verify_captcha`, formData, { headers: headers });
        if (response.status == 200) {
            return response.data
        }
    } catch (errors) {
        throw (errors)
    }
}