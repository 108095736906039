import logo from '../assets/images/logo.png'

const Loader = ()=>{

    return (
       <>
            <div className="auth-wrapper">
                <img src ={logo} style={{animation: "floating 2s infinite"}}/>
            </div>
       </>
    );

} 

export default Loader;