import { useEffect, useRef, useState } from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { useMediaQuery } from '@mui/system';

const Main = ({ children }) => {
	//close side on outside click
	const [isOpen, setIsOpen] = useState(false);

	//screen
	const isMobile = useMediaQuery('(max-width:991px)');

	const sidebarRef = useRef();
	const toggleButtonRef = useRef();

	const handleToggle = () => {
		setIsOpen(!isOpen);
	}

	const handler = (e) => {
		if (!sidebarRef.current.contains(e.target) && !toggleButtonRef.current.contains(e.target)) {
			setIsOpen(false);
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handler);
		return () => {
			document.removeEventListener('mousedown', handler);
		};
	}, [])

	useEffect(() => {
		if (!isMobile) {
			setIsOpen(false);
		}
	}, [isMobile])

	return (
		<div className="container-scroller">
			<Navbar handleToggle={handleToggle} toggleButtonRef={toggleButtonRef} />
			<div className="container-fluid page-body-wrapper">
				<nav className={`sidebar sidebar-offcanvas ${isOpen ? "active" : ""}`} id="sidebar" ref={sidebarRef}>
					<ul className="nav">
						<div className="fixed-sidebar">
							<Sidebar />
						</div>
					</ul>
				</nav>
				<div className="main-panel">
					{children}
					<Footer />
				</div>

			</div>

		</div>
	);
}

export default Main;