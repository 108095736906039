const host = window.location.host;

let baseURL = ''
let env = ''
if (host === 'localhost:3000') {
    baseURL = 'http://localhost:8000/'
    env = 'local'
} else if (host === 'uat-emanage.xscad2.com') {
    baseURL = 'https://uat-emanage-api.xscad2.com/'
    env = 'uat'
} else if (host === 'emanage.xscad2.com') {
    baseURL = 'https://emanage-api.xscad2.com/'
    env = 'production'
}

const appConfig = {
    baseURL: baseURL,
    env:env
}

export default appConfig