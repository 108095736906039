// Libraries
import { useState, useEffect } from 'react'

// MUI Library
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { makeStyles } from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles({
  textfeild: {
    "& .MuiFormLabel-root": {
      fontSize: '14px',
      lineHeight: '1 !important',
      left: '2px',
      maxWidth: '100% !important'
    },
    "& .MuiInputLabel-shrink": {
      lineHeight: '1.2 !important',
    },
    "& .MuiFormLabel-root.Mui-focused": {
      fontSize: '14px',
      left: '0px',
    },
    "& .MuiInputBase-root": {
      height: '34px',
      padding: '5px !important',
      "& input": {
        fontSize: '12px !important',
        padding: '5px !important'
      }
    }

  },
});

const FilterTextField = ({ placeholderLabel, name, value, onChangeFunction }) => {

  const classes = useStyles();
  const [fieldValue, setFieldValue] = useState('')
  useEffect(() => {
    setFieldValue(value)
  }, [value]);

  const onClear = (event) => {
    event.target.value = '';
    event.target.name = name;
    onChangeFunction(event)
  }

  return (
    <>
      <TextField
        size="small"
        variant="outlined"
        className={classes.textfeild}
        type="text"
        autoComplete="off"
        label={placeholderLabel}
        placeholder={placeholderLabel}
        name={name}
        value={fieldValue}
        fullWidth
        onChange={onChangeFunction}
        InputProps={{
          endAdornment: (
            fieldValue && <IconButton onClick={onClear}>
              <InputAdornment position="end">
                <CloseIcon sx={{ width: 14, hight: 14 }} />
              </InputAdornment>
            </IconButton>

          ),
        }}
      />
    </>
  )
}
export default FilterTextField