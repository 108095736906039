import { Stack, Typography } from "@mui/material";
import React from "react";
import nodataImg from "./../../assets/images/no-data.png"
export default function NoData({data}) {
  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0}
      >
        <img src={nodataImg} width={100}/>
        <Typography fontSize={14} mt={2} color={'#4D5565'}>{data}</Typography>
        <Typography fontSize={10} color={'#b4b4b4'}>
          No result
        </Typography>
      </Stack>
    </>
  );
}
