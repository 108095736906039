import API from '../util/api';
import { store } from '../redux/store'

const token = store.getState().auth.authToken;

// Get all Notifications
export const getAllNotifications = async (token1) => {
    try {
        const sanctumResponse = await API.get('/sanctum/csrf-cookie')
        let headers = sanctumResponse.config.headers;
        headers.Authorization = `Bearer ${token1}`

        const response = await API.get(`/api/get_all_notifications`, { headers: headers });
        if (response.status == 200) {
            return response.data
        }
    } catch (errors) {
        throw (errors)
    }
}

// Update Seen Notification
export const updateNotification = async (token1, id) => {
    try {
        const sanctumResponse = await API.get('/sanctum/csrf-cookie')
        let headers = sanctumResponse.config.headers;
        headers.Authorization = `Bearer ${token1}`

        const response = await API.post(`/api/update_notification_status/${id}`, {}, { headers: headers });
        if (response.status == 200) {
            return response.data
        }
    } catch (errors) {
        throw (errors)
    }
}

export const getEAppsCredentials = async (token1) => {
    try {
        const sanctumResponse = await API.get('/sanctum/csrf-cookie');
        let headers = sanctumResponse.config.headers;
        headers.Authorization = `Bearer ${token1}`;
        const response = await API.get(
            `/api/get_eapps_credentials`,
            {
                headers: headers,
            }
        );
        if (response.status == 200) {
            return response.data;
        }
    } catch (errors) {
        throw errors;
    }
};
