// Libraries
import { useState, useEffect } from 'react'

// MUI Library
import { TextField, IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    textfeild: {
        marginBottom:'1rem !important',
    "& .MuiFormLabel-root":{
      fontSize:'14px',
      lineHeight:'1 !important',
      left:'2px',
      maxWidth:'100% !important'
    },
    "& .MuiInputLabel-shrink":{
        lineHeight:'1.2 !important',
      },
    "& .MuiFormLabel-root.Mui-focused":{
      fontSize:'14px',
      left:'0px',
    },
    "& .MuiInputBase-root":{
      height:'34px',
      padding:'5px !important',
      "& input":{
        fontSize:'12px !important',
        padding:'5px !important'
      }
    },
    "& .MuiIconButton-root":{
        padding: "0 15px",
        "& svg":{
            width:"20px",
        }
    }
     
    },
  });

const FormPasswordField = ({ placeholderLabel, name, value, onChangeFunction, errorMsg, requiredFlag = false, visibility = false, disabled=false }) => {

    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisiblity = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <TextField
                sx={{ mb: 1.5 }}
                className={classes.textfeild}
                size="small"
                variant="outlined"
                autoComplete="off"
                fullWidth

                type={showPassword ? 'text' : 'password'}
                label={placeholderLabel}
                placeholder={placeholderLabel}
                name={name}
                value={value}
                onChange={onChangeFunction}
                helperText={errorMsg}
                error={errorMsg == '' ? false : true}
                required={requiredFlag}
                disabled={disabled}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={togglePasswordVisiblity}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }}
            />
        </>
    )
}
export default FormPasswordField