import React, { lazy } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import Loadable from './loadable';

// Lazy Loading Components
const Login = Loadable(lazy(() => import('../pages/auth/login')));
const ForgotPassword = Loadable(lazy(() => import('../pages/auth/forgot-password')));
const SetPassword = Loadable(lazy(() => import('../pages/auth/set-password')));

//-----------------------|| AUTHENTICATION ROUTING ||-----------------------//

const AuthenticationRoutes = () => {
    const location = useLocation();

    return (
            <Switch location={location} key={location.pathname}>
                <Route path="/login" render={(props) => <Login />} />
                <Route path="/forgot-password" render={(props) => <ForgotPassword />} />
                <Route path="/set-password/:token" render={(props) => <SetPassword />} />
                <Route exact path="*" render={() => <Redirect to="/login" />} />
            </Switch>
    );
};

export default AuthenticationRoutes;
