import moment from 'moment'
import Datevalidator from './datevalidator'
import Timevalidator from './timevalidator'
import { PhoneNumberUtil } from 'google-libphonenumber'

const FieldLimits = {
    FULLNAME: 255,
    FORENAME: 25,

    EMPLOYEECODE: 10,
    EMAILADDRESS: 50,
    PHONENUMBER: 30
}

const ValidationErrorMessages = {
    REQUIRED: 'Field is required',
    FIELDLIMIT: 'Field limit reached',

    NAMEFIELDFORMAT: 'Field cannot have numbers or unnecessary spaces',

    DATEOFBIRTH: 'The age should be above 18',

    EMAILFORMAT: 'Invalid email address.',
    PHONEFORMAT: 'Invalid phone number.',
    PINCODEFORMAT: 'Pincode should be 6 digit.',
    WEBSITEFORMAT: 'Website should be in proper format',
    EXPECTEDCLOSEDATE: 'Expected Close Date should be greater than date received',
    HOURSFORMAT: 'Value must be a number or float number',

    PASSWORDMATCH: 'Passwords should match',
    PASSWORDFORMAT: 'Password should contain atleast 1 Uppercase, 1 Lowercase, 1 Digit and 1 Special character',
    PASSWORDSTRENGTH: 'Passwords should be Strong',
    PASSWORDLENGTH: 'Password should be atleast 8 character long'
}

/* Validators
 * These functions take a value and validate it.
 */
export const FullNameValidator = (value) => {
    if (value == '' || value == null) {
        return ValidationErrorMessages.REQUIRED
    }
    else if (value.length > FieldLimits.FULLNAME) {
        return ValidationErrorMessages.FIELDLIMIT
    }
    else if (!value.match(/^[a-zA-z]+([\s][a-zA-Z]+)*$/)) {
        return ValidationErrorMessages.NAMEFIELDFORMAT
    }
    else {
        return ''
    }
}

export const ForenameValidator = (value) => {
    if (value == '' || value == null) {
        return ValidationErrorMessages.REQUIRED
    }
    else if (value.length > FieldLimits.FORENAME) {
        return ValidationErrorMessages.FIELDLIMIT
    }
    else if (!value.match(/^[a-zA-z]+([\s][a-zA-Z]+)*$/)) {
        return ValidationErrorMessages.NAMEFIELDFORMAT
    }
    else {
        return ''
    }
}

export const GeneralRequiredFieldValidator = (value) => {
    if (value == '' || value == null || value == 'null' || value.length == 0) {
        return ValidationErrorMessages.REQUIRED
    }
    return ''
}

export const DateOfBirthFieldValidator = (inputDate) => {
    var currentDate = moment();
    var inputDate = moment(inputDate);
    var diffDuration = moment.duration(currentDate.diff(inputDate));

    if (diffDuration.years() < 18) {
        return ValidationErrorMessages.DATEOFBIRTH
    } else if (inputDate == '' || inputDate == null) {
        return ValidationErrorMessages.REQUIRED
    }
    return ''
}


export const AddressErrorValidator = (value) => {
    for (var i = 0; i < value.length; i++) {
        var element = value[i];
        if (element.address_type == '' || element.address_line1 == '' || element.country == '' || element.city == '' || element.state == '' || element.pincode == '') {
            return 'All Address Fields are Required'
        }
    }
    return ''
}

export const EmailValidator = (value) => {
    if (value == '') {
        return ValidationErrorMessages.REQUIRED
    } else if (!value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
        return ValidationErrorMessages.EMAILFORMAT
    }
    return ''
}

export const PhoneValidator = (value) => {

    value = value.split('x')[0]

    if (value == '') {
        return ValidationErrorMessages.REQUIRED
    } else if (!value.match(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/)) {
        return ValidationErrorMessages.PHONEFORMAT
    } else {
        const phoneUtil = PhoneNumberUtil.getInstance();
        try {
            phoneUtil.isValidNumber(phoneUtil.parse('+' + value));
            return '';
        } catch (error) {
            return error.message
            // console.log(error.message)
        }
    }
}

//Pincode Validator
export const PinCodeValidator = (value) => {
    if (value == '') {
        return ValidationErrorMessages.REQUIRED
    }
    // var pat1 = /^\d{6}$/;
    // } else if (!pat1.test(value)) {
    //     return ValidationErrorMessages.PINCODEFORMAT
    // }
    return ''
}

//Datevalidator Validator
export const DateValidator = (value) => {
    if (value == '' || value == null || value == 'null') {
        return ValidationErrorMessages.REQUIRED
    } else if (!Datevalidator(value)) {
        return "Invalid Date"
    }
    return ''
}

//TImevalidator Validator
export const TimeValidator = (value) => {
    if (value == '' || value == null || value == 'null') {
        return ValidationErrorMessages.REQUIRED
    } else if (!Timevalidator(value)) {
        return "Invalid Time"
    }
    return ''
}

// Website validator
export const WebsiteValidator = (value) => {
    var pattern = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    //var pattern = /^(?:(?:https?|ftp|com):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (value == '') {
        return ValidationErrorMessages.REQUIRED
    } else if (!pattern.test(value)) {
        return ValidationErrorMessages.WEBSITEFORMAT
    }
    return ''

}

export const EmployeeCodeValidator = (value) => {
    if (value == '') {
        return ValidationErrorMessages.REQUIRED
    }
    else if (value.length > FieldLimits.EMPLOYEECODE) {
        return ValidationErrorMessages.FIELDLIMIT
    } else {
        return ''
    }
}


//Opportunity expected date validator
export const OppoClosedDateValidator = (closeddate, receiveddate) => {
    if (closeddate == '' || receiveddate == '') {
        return ValidationErrorMessages.REQUIRED
    } else if (moment(receiveddate).isBefore(closeddate) == false) {
        return ValidationErrorMessages.EXPECTEDCLOSEDATE
    } else {
        return ''
    }
}

//Hours Validator 
export const HoursValidator = (value) => {
    var pattern = /^[0-9]*([.,][0-9]+)?$/;

    if (value == '') {
        return ValidationErrorMessages.REQUIRED
    } else if (!pattern.test(value)) {
        return ValidationErrorMessages.HOURSFORMAT
    }
    return ''

}

// Password
// export const PasswordValidator = (password, confirmPassword, passwordStrength) => {
export const PasswordValidator = (password, confirmPassword) => {
    if (confirmPassword == '') {
        return ValidationErrorMessages.REQUIRED
    }
    else if (password.localeCompare(confirmPassword) != 0) {
        return ValidationErrorMessages.PASSWORDMATCH
    }
    else if (password.length < 8) {
        return ValidationErrorMessages.PASSWORDLENGTH
    }
    else if (!(
        new RegExp(/[0-9]/).test(password) &&
        new RegExp(/[a-z]/).test(password) &&
        new RegExp(/[A-Z]/).test(password) &&
        new RegExp(/[!#@$%^&*)(+=._-]/).test(password)
    )) {
        return ValidationErrorMessages.PASSWORDFORMAT
    }
    else {
        return ''
    }
}

// Notes
export const NotesValidator = (value) => {
    if (value == '<p></p>') {
        return ValidationErrorMessages.REQUIRED
    }
    else {
        return ''
    }
}