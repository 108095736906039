import {store} from '../redux/store'



export const getPermissionAccess = (moduleAlias, permissionAlias) => {
    
    const permissionsMatrix = store.getState().permissions.permissionsMatrix;

    if (permissionsMatrix && permissionsMatrix.length > 0) {
        const permission = permissionsMatrix.find(element => element.permission.module.alias == moduleAlias && element.permission.alias == permissionAlias)
        if (permission) {
            if (permission.flag == 1) {
                return true
            }else{
                return false
            }
        }
    }
}