import * as actionTypes from '../actions/permissionsActions';

const initialState = {
    permissionsMatrix: []
};
const permissionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_PERMISSIONS:
            return {
                ...state,
                permissionsMatrix: action.payload
            };
        case actionTypes.GET_PERMISSIONS:
            return state;
        case actionTypes.REMOVE_PERMISSIONS:
            return {
                ...state,
                permissionsMatrix:[]
            };
        default:
            return state;
    }
};
export default permissionsReducer;
