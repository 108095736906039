import generator from 'generate-password'

export const passwordGenerator = () => {

    const generatedPassword = generator.generate({
        length: 16,
        numbers:true,
        symbols:true,
        lowercase:true,
        uppercase:true,
        strict:true,
        exclude:':;,.|"^`~-'
    })

    return generatedPassword;
}