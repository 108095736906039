import moment from "moment";

function Timevalidator (timeVal){
        var check = moment(timeVal, 'hh:mm');
        var hrs = check.format('h');
        var mints   = check.format('m');
        if(hrs == 'Invalid date' || mints == 'Invalid date'){
            return false
        }
        else{
            return true
        }
    
}

export default Timevalidator