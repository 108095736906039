import * as actionTypes from '../actions/authActions';

const initialState = {
    name: null,
    imgsrc: null,
    empId: null,
    userId: null,
    department: null,
    designation: null,
    authToken: null,
    isLoggedIn: false,
    employeeCreatedAt: null,
    emails: []
};
const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN:
            return {
                ...state,
                name: action.name,
                imgsrc: action.imgsrc,
                userId: action.userId,
                empId: action.empId,
                department: action.department,
                designation: action.designation,
                authToken: action.token,
                isLoggedIn: true,
                employeeCreatedAt: action.employeeCreatedAt,
                emails: action.employeeEmails
            }
        case actionTypes.LOGOUT:
            return {
                ...state,
                name: null,
                userId: null,
                imgsrc: null,
                empId: null,
                department: null,
                designation: null,
                authToken: null,
                isLoggedIn: false,
                employeeCreatedAt: null,
                emails: []
            }
        default:
            return state;
    }
};
export default authReducer;
