import swal from 'sweetalert';

export const successSwal = (swalConfig) => {
  let action = '';
  switch (swalConfig.action) {
    case 'add':
      action = 'created';
      break;
    case 'update':
      action = 'updated';
      break;
    case 'delete':
      action = 'deleted';
      break;
    case 'restore':
      action = 'restored';
      break;
    case 'save':
      action = 'saved';
      break;
    case 'raise':
      action = 'raised';
      break;
    case 'sent':
      action = 'sent';
      break;
    case 'draft':
      action = 'drafted';
      break;
    case 'complete':
      action = 'completed';
      break;
    case 'approve':
      action = 'approved';
      break;
    case 'reject':
      action = 'rejected';
      break;
    default:
      action = '';
  }

  swal({
    title: 'Success',
    text: `${swalConfig.module} has been ${action} successfully`,
    icon: 'success',
  });
};

export const errorSwal = (swalConfig) => {
  swal({
    title: 'Error',

    text: `Well, this is unexpected…
        Error code: 500
        An error has occurred and we’re working to fix the problem!
        We will be up and running shortly.
        If you need immediate help, Please contact software development team.
        Thanks for your patience!`,

    icon: 'error',
  });

  // let action = ''
  // switch (swalConfig.action) {
  //     case 'add':
  //         action = 'created'
  //         break;
  //     case 'update':
  //         action = 'updated'
  //         break;
  //     case 'delete':
  //         action = 'deleted'
  //         break;
  //     case 'restore':
  //         action = 'restored'
  //         break;
  //     default:
  //         action = 'not assigned'
  // }

  // swal({
  //     title: "Success",
  //     text: `${swalConfig.module} ${action} successfully`,
  //     icon: "success",
  // });
};
