import { createStore } from 'redux';

// Persist Reducer
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

// Local imports
import reducer from '../reducers/reducer';

// Persistence
const persistConfig = {
    key: 'root',
    storage
};

const persistedReducer = persistReducer(persistConfig, reducer);

//-----------------------|| REDUX - MAIN STORE ||-----------------------//

const store = createStore(persistedReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
const persistor = persistStore(store);

// const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));
export { store, persistor };
