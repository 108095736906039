
// action - state management
import * as actionTypes from '../actions/customisationAction';

export const initialState = {
    active_tab: 0
};

//-----------------------|| CUSTOMIZATION REDUCER ||-----------------------//

const customizationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ACTIVETAB:
            return {
                ...state,
                active_tab: action.active_tab
            };
        default:
            return state;
    }
};

export default customizationReducer;
