import { DataGrid } from "@mui/x-data-grid";
import ClientDatagrid from "../../components/datatable/client-datagrid";
import ServerDatagrid from "../../components/datatable/server-datagrid";
import { makeStyles } from "@material-ui/core";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";

const useStyles = makeStyles({
    datagrid1: {
        width: '100%',
        marginBottom: "10px",

        "& .MuiDataGrid-virtualScroller": {
            overflow: 'auto !important'
        },
        "& .MuiDataGrid-renderingZone": {
            maxHeight: "fit-content !important"
        },
        "& .MuiDataGrid-cell": {
            lineHeight: "unset !important",
            maxHeight: "none !important",
            whiteSpace: "normal",
            fontSize: '0.75rem !important'
        },
        "& .MuiDataGrid-columnHeaderTitleContainer": {
            fontSize: '0.75rem !important',
            fontWeight: '700 !important',
            "& .MuiDataGrid-columnHeaderTitle": {
                color: '#4D5565',
                fontSize: '0.82rem !important',
                fontWeight: '500 !important',
            }
        },
        "& .MuiDataGrid-row": {
            maxHeight: "none !important",
            fontSize: '0.75rem !important',
            fontWeight: '500 !important',

        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            borderRight: '1px solid #f0f0f0',
        },
        '& .MuiDataGrid-cell': {
            color: 'rgba(77, 85, 101, 0.9)',
        }, '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        }, '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            borderBottom: '1px solid #f0f0f0',
        },
        "& .MuiDataGrid-main,": {
            maxHeight: '350px',
            overflowY: 'revert',
        },
        // "& .MuiDataGrid-virtualScrollerRenderZone":{
        //     maxHeight: '350px',
        //      overflowY: 'auto',
        // }
    },

});

const TableModal = ({ tableData, tableConfig,isFetching=false }) => {
    const [listingData, setListingData] = useState([]);

    useEffect(() => {
        setListingData(tableData);
    }, [tableData]);
    const classes = useStyles();

    return (
        <>
            <DataGrid
                rows={listingData}
                autoHeight

                headerHeight={35}
                getRowId={(row) => row.id}
                columns={tableConfig.columns}
                className={classes.datagrid1}
                // getRowHeight={() => 'auto'}
                disableSelectionOnClick={true}
                disableColumnMenu={true}
                disableColumnFilter={true}
                disableColumnSelector={true}
                loading={isFetching}
                components={{
                    NoRowsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            No Data Available
                        </Stack>
                    ),
                    NoResultsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            No Data Available
                        </Stack>
                    )
                }}

            />
        </>)
}

export default TableModal;