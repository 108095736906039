import moment from "moment";

function Datevalidator (dateVal){
        var check = moment(dateVal, 'DD/MM/YYYY');

        var month = check.format('MM');
        var day   = check.format('DD');
        var year  = check.format('YYYY');

        if(month == 'Invalid date' || day == 'Invalid date' || year == 'Invalid date'){
            return false
        }
        else{
            return true
        }
    
}

export default Datevalidator