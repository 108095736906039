import { useEffect, useState } from 'react';
import {
    DataGrid, GridToolbarQuickFilter,

} from '@mui/x-data-grid';
import { Box, Stack } from '@mui/material';
import { makeStyles, } from "@material-ui/core";
import { CustomPagination } from './custompagination';
import NoData from '../extension/nodata';




const useStyles = makeStyles({
    datagrid1: {
        width: "100%",
    
        "& .MuiDataGrid-virtualScroller": {
          overflow: "auto !important",
        },
        "& .MuiDataGrid-renderingZone": {
          maxHeight: "fit-content !important",
        },
        "& .MuiDataGrid-cell": {
          lineHeight: "unset !important",
          maxHeight: "none !important",
          whiteSpace: "normal",
          fontSize: "0.75rem !important",
        },
        "& .MuiDataGrid-columnHeaderTitleContainer": {
          fontSize: "0.75rem !important",
          fontWeight: "700 !important",
          "& .MuiDataGrid-columnHeaderTitle": {
            color: "#4D5565",
            fontSize: "0.82rem !important",
            fontWeight: "500 !important",
          },
        },
        "& .MuiDataGrid-row": {
          maxHeight: "none !important",
          fontSize: "0.75rem !important",
          fontWeight: "500 !important",
        },
        "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
          borderRight: "1px solid #f0f0f0",
        },
        "& .MuiDataGrid-cell": {
          color: "rgba(77, 85, 101, 0.9)",
        },
        "& .MuiDataGrid-iconSeparator": {
          display: "none",
        },
        "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
          borderBottom: "1px solid #f0f0f0",
        },
      },
    });

function ClientDatagrid({ tableData, tableConfig }) {
    const classes = useStyles();
    const [listingData, setListingData] = useState({
        data: [],
        loading: true
    });

    const [pageSize, setPageSize] = useState(5);

    const type = 'client';

    useEffect(() => {
        if (tableData.data != undefined) {
            setListingData({...tableData,defaultrows:pageSize});
        }
    }, [tableData]);

    return (
        <div style={{ width: '100%' }}>
            <DataGrid
                rows={listingData.data}
                autoHeight
                getRowId={(row) => row.id}
                columns={tableConfig.columns}
                rowHeight={40}
                headerHeight={45}
                className={classes.datagrid1}
                pageSize={pageSize}
                rowsPerPageOptions={[5, 10, 20, 30]}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                paginationMode="client"
                loading={listingData.loading}
                components={{
                    Footer: CustomPagination,
                    NoRowsOverlay: () => (
                        <NoData/>
                    ),
                    NoResultsOverlay: () => (
                        <NoData/>
                    ),
                    Toolbar: () => (
                        <Box p={1}>
                            <GridToolbarQuickFilter
                                size="small"
                                variant="outlined"
                                // sx={{ width: '180px', mr: 1, '& fieldset': { borderRadius: '4px' }, '& input': { padding: '8.5px 14px !important' } }}
                                type="text"
                                autoComplete="off"
                                placeholder="Search"
                                quickFilterParser={(searchInput) => searchInput.split(',').map((value) => value.trim())}
                                quickFilterFormatter={(quickFilterValues) => quickFilterValues.join(', ')}
                                debounceMs={200} // time before applying the new quick filter value
                            />
                        </Box>
                    )
                }}
                componentsProps={{
                    footer: { listingData, type },
                }}
                disableSelectionOnClick={true}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableColumnMenu={true}
            />
        </div>
    );
}

export default ClientDatagrid;
