
// action - state management
import * as actionTypes from '../actions/empAction';

export const initialState = {
    pageView : 'list',
    active_tab: 0
};

//-----------------------|| Emp REDUCER ||-----------------------//

const empReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_EMPPAGE:
            return {
                ...state,
                pageView : action.pageView,
                active_tab: action.active_tab
            };
        default:
            return state;
    }
};

export default empReducer;
