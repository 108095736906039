import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "./../assets/images/logo.png";

import { useQuery } from "react-query";

import Cookies from "universal-cookie";
import axios from "axios";
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Modal,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";

// Utilities
import { useSelector, useDispatch } from "react-redux";
import swal from 'sweetalert';
import {
  EAppsicon,
  Fullscreenviewicon,
  Logominicon,
  Modalcloseicon,
  Notificationicon,
} from "../assets/svgicons";
import { makeStyles } from "@material-ui/core";
// import { makeStyles } from '@mui/styles';

// Redux Actions
import { LOGOUT } from ".././redux/actions/authActions";
import { REMOVE_PERMISSIONS } from ".././redux/actions/permissionsActions";
import { logoutSession } from "../services/auth-service";
import { errorSwal } from "../util/sweet-alert";
import appConfig from "../util/app-config";
import ChangePassword from "../pages/auth/change-password";
import {
  getAllNotifications,
  updateNotification,
  getEAppsCredentials,
} from "../services/notification-service";
import TableModal from "../pages/dashboard/table-modal";

const useStyles = makeStyles({
  roundedicons: {
    boxShadow: "0px 2px 8px rgb(206 206 206 / 45%)",
    borderRadius: "50px",
    display: "inline-block",
    textAlign: "center",
    padding: "4px 10px",
    "&:hover": {
      boxShadow: "none",
    },
  },
});

const style = {
  position: "absolute",
  // top: "50%",
  left: "50%",
  transform: "translate(-50%, 10%)",
  width: "80%",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 2,
};


const Navbar = ({ handleToggle, toggleButtonRef }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.auth.authToken);
  const logindata = useSelector((state) => state.auth);
  const [openchangepassword, setOpenChangepassword] = useState(false);
  const history = useHistory();

  const [tabledisplay, setTabledisplay] = useState(false);

  const { data: notifications, refetch: refetchNotifications } = useQuery(
    "notifications",
    () => getAllNotifications(token)
  );

  const [loginList, setLoginList] = useState([]);
  const [anchorElLoginList, setAnchorElLoginList] = useState(null);

  const handleCloseLoginList = () => {
    setAnchorElLoginList(null);
  };

  const openLoginList = Boolean(anchorElLoginList);

  const updateNotificationStatus = async (notificationId) => {
    try {
      const response = await updateNotification(token, notificationId);
      refetchNotifications();
    } catch (error) {
      errorSwal();
    }
  };

  const logout = async () => {
    try {
      const response = await logoutSession(token);
      dispatch({ type: REMOVE_PERMISSIONS });
      dispatch({ type: LOGOUT });
      history.push("/login");
      window.location.reload();
    } catch (error) {
      errorSwal();
    }
  };
  // Table Config
  const notificationConfig = {
    columns: [
      {
        field: "",
        headerName: "No.",
        width: 50,
        renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
      },
      // {
      // 	field: 'related_to',
      // 	headerName: 'Related to',
      // 	minWidth: 250,
      // 	headerAlign: 'center',
      // 	align: 'center',
      // 	cellClassName: 'text-warp',
      // 	renderCell: (params) => (
      // 		<>
      // 			{params.row.related_to}
      // 		</>
      // 	)
      // },
      {
        field: "text",
        headerName: "Description",
        flex: 1,
        headerAlign: "center",
        align: "center",
        cellClassName: "text-warp",
        renderCell: (params) => <>{params.row.text}</>,
      },
      {
        headerName: "Action",
        width: 180,
        headerAlign: "center",
        align: "center",
        cellClassName: "text-warp",
        renderCell: (params) => (
          <>
            <Box
              className="pointer"
              onClick={() => updateNotificationStatus(params.row?.id)}
            >
              <Typography color="#4D5565" fontWeight={700} fontSize={12}>
                Mark as Read
              </Typography>
            </Box>
          </>
        ),
      },
    ],
  };

  const onClickHandler = async (event) => {
    try {
      const eAppResponse = await getEAppsCredentials(token)

      if (eAppResponse?.error) {
        swal({
          title: 'Error',
          text: `Credentials are not in eApplication`,
          icon: 'error',
        });
      } else {
        setLoginList(eAppResponse)
      }

      //  Laravel BE - get eapp credentials
      if (eAppResponse?.length > 1) {
        setAnchorElLoginList(event.currentTarget);
      } else {
        const response = await axios.post(
          "https://eapps.xscad2.com/eApplications/auth/login",
          {
            username: eAppResponse?.[0]?.username,
            password: eAppResponse?.[0]?.decrypted,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );

        if (response.data != "no_access") {
          let params = encodeURIComponent(JSON.stringify(response.data));
          window.open(
            "https://eapps.xscad2.com/eApplications/index/dashboard?params=" +
            params,
            "_blank"
          );
        } else {
          swal({
            title: 'Error',
            text: `eApps Password is Invalid`,
            icon: 'error',
          });
        }
      }
    } catch (error) {
      errorSwal()
    }
  };

  const loginAsUser = async (user) => {
    try {
      const response = await axios.post(
        "https://eapps.xscad2.com/eApplications/auth/login",
        {
          username: user?.username,
          password: user?.decrypted,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.data != "no_access") {
        let params = encodeURIComponent(JSON.stringify(response.data));
        window.open(
          "https://eapps.xscad2.com/eApplications/index/dashboard?params=" +
          params,
          "_blank"
        );
      } else {
        swal({
          title: 'Error',
          text: `eApps Password is Invalid`,
          icon: 'error',
        });
      }
    }
    catch (err) {
      errorSwal();
    }
  }

  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <a className="navbar-brand brand-logo" href="index.html">
          <img src={logo} alt="logo" />
        </a>
        <a className="navbar-brand brand-logo-mini" href="index.html">
          <Logominicon width={30} />
        </a>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        <button
          className="navbar-toggler navbar-toggler align-self-center"
          type="button"
          data-toggle="minimize"
        >
          <span className="mdi mdi-menu"></span>
        </button>

        <ul className="navbar-nav navbar-nav-right">
          {/* <li className="nav-item d-none d-lg-block full-screen-link">
            <Tooltip title="eApps">
              <Box
                onClick={(e) => onClickHandler(e)}
                className={classes.roundedicons}
              >
                <EAppsicon width={16} />
              </Box>
            </Tooltip>
          </li> */}
          <li className="nav-item d-none d-lg-block full-screen-link">
            <a className="nav-link">
              <Tooltip title="Fullscreen">
                <Box className={classes.roundedicons} id="fullscreen-button">
                  <Fullscreenviewicon width={15} />
                </Box>
              </Tooltip>
            </a>
          </li>

          <li className="nav-item dropdown">
            <a
              className="nav-link count-indicator dropdown-toggle"
              onClick={() => refetchNotifications()}
              id="notificationDropdown"
              href="#"
              data-toggle="dropdown"
            >
              <Tooltip title="Notification">
                <Box className={classes.roundedicons}>
                  <Notificationicon width={18} />
                </Box>
              </Tooltip>
            </a>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
              aria-labelledby="notificationDropdown"
              style={{ minWidth: 270 }}
            >
              <h6 className="p-3 mb-0">Notifications</h6>
              <div className="dropdown-divider"></div>
              {notifications &&
                notifications.map((o, i) => {
                  if (o?.seen == "no" && i <= 5) {
                    return (
                      <>
                        <Box onClick={() => updateNotificationStatus(o?.id)}>
                          {/* {JSON.stringify(o)} */}
                          <a className="dropdown-item preview-item">
                            {/* <div className="preview-thumbnail">
															<div className="preview-icon">
																<Avatar src={appConfig.baseURL + logindata.imgsrc} alt={logindata.name} sx={{ width: 27, height: 27 }} />
															</div>
														</div> */}
                            <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                              {/* <h6 className="preview-subject font-weight-normal mb-1">{o?.related_to}</h6> */}
                              <Tooltip title={o?.text}>
                                <p className="text-gray ellipsis mb-0">
                                  {" "}
                                  {o?.text}{" "}
                                </p>
                              </Tooltip>
                            </div>
                          </a>
                        </Box>
                        <div className="dropdown-divider"></div>
                      </>
                    );
                  }
                })}
              <div className="dropdown-divider"></div>
              <Box onClick={() => setTabledisplay(true)}>
                <h6 className="p-3 mb-0 text-center pointer">
                  See all notifications
                </h6>
              </Box>
            </div>
          </li>

          <li className="nav-item nav-profile dropdown">
            <a
              className="nav-link dropdown-toggle"
              id="profileDropdown"
              href="#"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="nav-profile-text">
                <p className="mb-1 text-black">{logindata.name}</p>
              </div>
              <div className="nav-profile-img">
                {/* <img src="assets/images/faces/face1.jpg" alt="image" /> */}
                <Avatar
                  src={appConfig.baseURL + logindata.imgsrc}
                  alt={logindata.name}
                  sx={{ width: 27, height: 27 }}
                ></Avatar>
                <span className="availability-status online"></span>
              </div>
            </a>
            <div
              className="dropdown-menu navbar-dropdown"
              aria-labelledby="profileDropdown"
            >
              <div className="dropdown-divider"></div>
              <span
                className="dropdown-item"
                onClick={() => history.push("/panel-queries")}
              >
                Help Desk
              </span>
              <span
                className="dropdown-item"
                onClick={() => setOpenChangepassword(true)}
              >
                Change Password
              </span>
              <span className="dropdown-item" onClick={logout}>
                Sign Out
              </span>
            </div>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          data-toggle="offcanvas"
          ref={toggleButtonRef} onClick={handleToggle}
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
      <ChangePassword
        openchangepassword={openchangepassword}
        setOpenChangepassword={setOpenChangepassword}
      />


      {/* Modal */}
      <Modal
        open={tabledisplay}
        onClose={() => setTabledisplay(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="taskmodal">
          <Box
            className={"pointer pull-right"}
            onClick={() => setTabledisplay(false)}
          >
            <Modalcloseicon width={18} />
          </Box>
          <p className="title">Notification</p>
          <Divider sx={{ mt: 1, mb: 1 }} />
          <TableModal
            tableData={notifications ? notifications : []}
            tableConfig={notificationConfig}
          />
        </Box>
      </Modal>
      <Popover
        open={openLoginList}
        anchorEl={anchorElLoginList}
        onClose={handleCloseLoginList}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List size="small">
          {loginList?.map((o, i) => (
            <ListItem size="small" disablePadding>
              <ListItemButton onClick={() => loginAsUser(o)} size="small">{o?.username}</ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popover>
    </nav>
  );
};

export default Navbar;
