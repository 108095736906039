import { useEffect, useState } from 'react';
import {
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
    gridPaginationRowRangeSelector,
    gridPageSizeSelector
} from '@mui/x-data-grid';
import { Divider, MenuItem, TablePagination, Typography, useMediaQuery } from '@mui/material';
import { Select } from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import { createTheme, ThemeProvider } from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles, } from "@material-ui/core";
import { minWidth, padding } from '@mui/system';

const useStyles = makeStyles({
    pagistyle: {
        color: 'rgba(77, 85, 101, 0.5)',
        "& .MuiButtonBase-root": {
            color: 'rgba(77, 85, 101, 0.5)'
        },
        "& .MuiButtonBase-root.Mui-selected": {
            color: "#4d5565",
            border: "1px solid rgb(80 88 105)",
            backgroundColor: "rgb(80 88 105 / 9%)"
        },
        "& .MuiPagination-ul": {
            justifyContent: 'center',
        }
    },
    entrydrpdwn: {
        height: "26px",
        margin: "0 6px",
        fontSize: "0.875rem",
        "& .MuiSelect-root.MuiSelect-select": {
            padding: "0px 21px 0 8px",
        },
        "& .MuiSvgIcon-root.MuiSelect-icon": {
            right: '0px'
        }


    },
    tablePagination: {
        '& .MuiTablePagination-select': {
            color: '#4D5565', // Change text color 
            // height: "26px",
            width: 18,
            border: "1px solid #c4c4c4",
            borderRadius: "5px",
            padding: "3px 0px",
            '&:hover': {
                border: "1px solid black"
            }
        },
        '& .MuiTablePagination-select:focus': {
            border: "2px solid grey",
            borderRadius: "5px",
        },
        '& .MuiTablePagination-selectLabel': {
            fontSize: "12px",
            color: "#4D5565"
        },
        '& .MuiTablePagination-displayedRows': {
            fontSize: "12px",
            color: "#4D5565"

        },
        '& .MuiTablePagination-actions': {
            '& .MuiIconButton-root': {
                color: "#4d556580",
                '&:disabled': {
                    opacity: 0.5,
                },
            },
        },
        '& .MuiTablePagination-toolbar': {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            paddingLeft: 0,

        }
    }
});

function CustomPagination(props) {
    const [pageSize, setPageSize] = useState(null);
    const classes = useStyles();
    // console.log("PRops : " ,pageSize)
    const theme = createTheme({
        palette: {
            primary: {
                light: '#757ce8',
                main: '#798294',
                dark: '#242d3c',
                contrastText: '#fff',
            }
        },
    });

    //responsive
    const isMobile = useMediaQuery('(max-width:991px)');

    //wrap arrow buttons of table pagination under screen size
    const wrapPagination = useMediaQuery('(max-width:377px)')

    useEffect(() => {
        setPageSize(props.listingData.defaultrows)
    }, [props.listingData.defaultrows])

    useEffect(() => {
        setPageSize(props.listingData.defaultrows)
    }, [props.listingData.defaultrows])

    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pagesize = useGridSelector(apiRef, gridPageSizeSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const rowRangeVisible = useGridSelector(apiRef, gridPaginationRowRangeSelector);



    return (
        <ThemeProvider theme={theme}>
            <div>
                <Divider />
                {
                    isMobile ?
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <TablePagination
                                component="div"
                                count={props.type == 'server' ? props.listingData.totalRecords : props.listingData.data.length}
                                rowsPerPage={pagesize}
                                page={page}
                                // labelRowsPerPage="Show Rows"
                                onPageChange={(event, value) => { apiRef.current.setPage(value) }}
                                rowsPerPageOptions={[5, 10, 20, 30]}
                                onRowsPerPageChange={(event) => { apiRef.current.setPageSize(event.target.value); setPageSize(event.target.value) }}
                                className={classes.tablePagination}
                                sx={{
                                    '& .MuiTablePagination-toolbar': {
                                        paddingTop: wrapPagination ? "5%" : 0
                                    },
                                    '& .MuiTablePagination-actions': {
                                        marginLeft: wrapPagination ? "0px !important" : "20px"
                                    }
                                }}
                            />
                        </div>
                        :
                        <div style={{ display: "flex", justifyContent: "space-between", margin: '10px' }}>

                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography variant='caption' color={"#4D5565"} lineHeight={2}> Show </Typography>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={pageSize}
                                    variant="outlined"
                                    className={classes.entrydrpdwn}
                                    onChange={(event) => { apiRef.current.setPageSize(event.target.value); setPageSize(event.target.value) }}
                                >
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                </Select>
                                <Typography variant='caption' color={"#4D5565"} lineHeight={2}> Rows </Typography>
                            </div>

                            <Pagination
                                color="primary"
                                size="small"
                                variant="outlined" shape="rounded"
                                count={pageCount}
                                page={page + 1}
                                className={classes.pagistyle}
                                onChange={(event, value) => apiRef.current.setPage(value - 1)}
                            />

                            {/* {JSON.stringify(rowRangeVisible)}
{rowRangeVisible && JSON.stringify((rowRangeVisible.firstRowIndex+1)*(page+1))}
{rowRangeVisible && JSON.stringify((rowRangeVisible.lastRowIndex+1)*(page+1))} */}

                            <Typography variant='caption' color={"#4D5565"} lineHeight={2}>
                                {
                                    props.type == 'server' && `Showing ${props.listingData.from ? props.listingData.from : '0'} - ${props.listingData.to ? props.listingData.to : '0'} of ${props.listingData.totalRecords}`
                                }
                                {
                                    props.type == 'client' && rowRangeVisible &&
                                    `Showing ${rowRangeVisible.firstRowIndex + 1} - ${rowRangeVisible.lastRowIndex + rowRangeVisible.firstRowIndex + 1 - (pagesize * page)} of ${props.listingData.data.length}`
                                }
                            </Typography>


                        </div>

                }



            </div>
        </ThemeProvider>
    );
}
CustomPagination.propTypes = {
    total: PropTypes.number
};

export { CustomPagination };