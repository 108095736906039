// Libraries
import React, { Suspense, useState, useEffect } from 'react';

// Style
import './App.scss';

import Routes from './routes/routes';

function App() {

	return (
		<>
			<Routes />
		</>
	);
}

export default App;
